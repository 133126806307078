import { NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	computed,
	DestroyRef,
	ElementRef,
	OnDestroy,
	OnInit,
	signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@app/_modules/translate';
import { FooterService, SettingsService } from '@app/_services';
import { environment } from '@env/environment';
import { catchError, of } from 'rxjs';
import { IconComponent } from '../icon/icon.component';
import { LineComponent } from '../line/line.component';
import {
	FooterDataResponse,
	FooterIcon,
	MappedFooterData,
} from './footer.model';
import { AppPipes } from '@app/_pipes';

const socialsIconMap = {
	facebook: 'facebook',
	instagram: 'instagram',
	podcasts: 'podcast_alt',
};

@Component({
    selector: 'htm-footer',
    templateUrl: 'footer.template.html',
    styleUrls: ['footer.styles.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        LineComponent,
        TranslateModule,
        NgIf,
        NgFor,
        RouterLink,
        IconComponent,
        AppPipes,
        NgTemplateOutlet,
    ]
})
export class FooterComponent implements OnInit, OnDestroy {
	protected footerData?: MappedFooterData;
	protected version = environment.VERSION;
	protected logoTemplate = null;
	protected inViewport = false;
	protected intersectionObserver?: IntersectionObserver = undefined;
	private fetchedFooterLinks = signal([]);
	protected footerLinks = computed(() =>
		this.footerService
			.additionalFooterLinks()
			.concat(this.fetchedFooterLinks()),
	);
	constructor(
		private httpClient: HttpClient,
		private settingsService: SettingsService,
		private cdr: ChangeDetectorRef,
		private destroyRef: DestroyRef,
		protected footerService: FooterService,
		private elementRef: ElementRef,
	) {}

	ngOnInit(): void {
		this.fetchFooterData();
		// Fetch data again when language changes, as data is language specific
		this.settingsService.activeLang$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => this.fetchFooterData());

		this.initIntersectionObserver();
	}

	ngOnDestroy(): void {
		if (this.intersectionObserver) {
			this.intersectionObserver?.disconnect();
		}
	}

	private fetchFooterData() {
		this.httpClient
			.get(
				`${this.settingsService.url}/api/htm-custom-rest-footer-settings?_format=json&lang=${this.settingsService.currentAppLanguage}`,
			)
			.pipe(catchError(() => of({})))
			.subscribe((res: FooterDataResponse) => {
				this.modifyFooterData(res);
			});
	}

	private modifyFooterData(data: FooterDataResponse) {
		// Mapping data for easier use
		const mappedSocials = Object.entries(data?.third ?? {})?.map(
			([icon, url]) =>
				({ icon: socialsIconMap?.[icon], url, label: icon }) as FooterIcon,
		);
		this.fetchedFooterLinks.set(data?.second?.links);
		this.footerData = {
			first: data?.first,
			third: mappedSocials,
		};
		// Tell angular to render again
		this.cdr.markForCheck();
	}

	private initIntersectionObserver() {
		const threshold = 0.1;
		const observer = new IntersectionObserver(
			(entries) => {
				const currentValue = this.inViewport;
				entries.forEach((entry) => {
					this.inViewport = entry?.isIntersecting;
				});
				if (this.inViewport != currentValue) {
					this.cdr.markForCheck();
				}
			},
			{ threshold },
		);
		observer.observe(this.elementRef.nativeElement);
	}
}
