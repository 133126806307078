import { Pipe, PipeTransform } from '@angular/core';
import { SettingsService } from '@app/_services';

@Pipe({
    name: 'getByCurrentLang',
    standalone: false
})
export class GetByCurrentLangPipe implements PipeTransform {
	constructor(private settingsService: SettingsService) {}

	transform(value: Object): string {
		// Avoid overriding if it's not an object
		if (typeof value === 'object') {
			return value?.[this.settingsService.currentAppLanguage];
		}
		return value;
	}
}
