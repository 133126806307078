import {
    Component,
    Input,
  } from '@angular/core';
@Component({
    selector: 'studyProgrammes',
    templateUrl: 'studyProgrammes.template.html',
    standalone: false
})

  export class StudyProgrammesComponent{
  @Input() list: Object[];
}
