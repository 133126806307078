import { NgModule, ModuleWithProviders, inject, provideAppInitializer } from '@angular/core';
import { TranslatePipe } from './translate.pipe';
import { TranslateService } from './translate.service';
import { CommonModule } from '@angular/common';

export function translationsProviderFactory(provider: TranslateService) {
	return () => provider.load();
}

@NgModule({
	imports: [CommonModule],
	declarations: [TranslatePipe],
	exports: [TranslatePipe],
})
export class TranslateModule {
	static forRoot(): ModuleWithProviders<TranslateModule> {
		return {
			ngModule: TranslateModule,
			providers: [
				TranslateService,
				provideAppInitializer(() => {
        const initializerFn = (translationsProviderFactory)(inject(TranslateService));
        return initializerFn();
      }),
			],
		};
	}
}
