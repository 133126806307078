export enum FeatureFlags {
	DARK_MODE = 'darkmode',
	MY_DATA_ENABLED = 'my_data_enabled',
	MY_DATA_NEW_STUDY_VIEW_ENABLED = 'my_data_new_study_view_enabled',
	GLOBAL_ALERT_ENABLED = 'global_alert_enabled',
	XJSON_DISABLE_QUEUE = 'xjson_disable_queue',
}

const FeatureFlagExpectedValues = {
	[FeatureFlags.DARK_MODE]: true,
	[FeatureFlags.MY_DATA_ENABLED]: true,
	[FeatureFlags.MY_DATA_NEW_STUDY_VIEW_ENABLED]: true,
	[FeatureFlags.GLOBAL_ALERT_ENABLED]: true,
	[FeatureFlags.XJSON_DISABLE_QUEUE]: true,
} satisfies Record<FeatureFlags, unknown>;

export type FeatureFlagExpectedValuesTypes = typeof FeatureFlagExpectedValues;
