import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	Input,
	ViewChild,
	forwardRef,
	inject,
	input,
	viewChild,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { dateFormat } from '@app/_core/models/date-time-constants';
import moment from 'moment';
import { GetBaseInputValue } from '../../models/base-input.types';
import { BaseInputComponent } from '../base-input/base-input.component';

/**
 * @deprecated Use new inputs instead
 */
@Component({
    selector: 'input-date',
    templateUrl: './input-date.template.html',
    styleUrls: ['./input-date.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => InputDateComponent),
        },
        // Replace the base component in contentchildren etc searches
        {
            provide: BaseInputComponent,
            useExisting: forwardRef(() => InputDateComponent),
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class InputDateComponent extends BaseInputComponent<Date | string> {
	private readonly cdr = inject(ChangeDetectorRef);
	protected innerValue = new FormControl<Date>(null);
	// This is not checked for validity
	protected innerInputValue = null;
	protected inputDisabled = false;
	protected innerMinDate = null;
	protected innerMaxDate = null;
	protected isFocused = false;
	propagateOnChange: (...args: unknown[]) => void;
	propagateOnBlur: (...args: unknown[]) => void;
	@ViewChild('input') matInput: ElementRef<HTMLInputElement>;
	@Input() title?: string;
	@Input() ariaLabel?: string;
	@Input() name: string;
	@Input() validate?: boolean;

	pickerRef = viewChild<any>('picker');
	format = input(dateFormat);
	ngAfterViewInit() {
		// Override datepicker format
		if (this.pickerRef()) {
			this.pickerRef().datepickerInput._dateFormats = {
				display: {
					dateA11yLabel: 'LL',
					dateInput: this.getActiveFormat(),
					monthYearA11yLabel: 'YYYY',
					monthYearLabel: 'YYYY',
				},
				parse: {
					dateInput: this.getActiveFormat(),
				},
			};
		}
	}

	@Input()
	get minDate() {
		return this.innerMinDate;
	}
	set minDate(value: unknown) {
		if (value instanceof Date && !isNaN(value?.getTime())) {
			this.innerMinDate = value;
		}
	}

	@Input()
	get maxDate() {
		return this.innerMaxDate;
	}
	set maxDate(value: unknown) {
		if (value instanceof Date && !isNaN(value?.getTime())) {
			this.innerMaxDate = value;
		}
	}

	@Input()
	get disabled() {
		return this.inputDisabled;
	}
	set disabled(state: boolean) {
		if (!this.innerForceDisabled) {
			this.inputDisabled = state;
		}
	}

	private innerForceDisabled?: boolean;
	@Input()
	public get forceDisabled(): boolean {
		return this.innerForceDisabled;
	}
	public set forceDisabled(value: boolean) {
		this.innerForceDisabled = value;
	}

	@HostListener('focus', ['$event']) onFocus() {
		this.isFocused = true;
	}

	@HostListener('blur', ['$event']) triggerBlur() {
		this.isFocused = false;
		this.propagateOnBlur?.();
		if (!this.filledField && this.matInput?.nativeElement?.value) {
			this.matInput.nativeElement.value = '';
		}
	}

	get filledField() {
		return !!this.innerValue?.value || moment(this.innerInputValue).isValid();
	}

	registerOnChange(fn: (...args: unknown[]) => void): void {
		this.propagateOnChange = fn;
	}

	registerOnTouched(fn: (...args: unknown[]) => void): void {
		this.propagateOnBlur = fn;
	}
	public writeValue(value: Date | string): void {
		if (this.validate) {
			this.writeValueWithMoment(value);
		} else if (this.innerValue?.value !== value) {
			const isDate = value instanceof Date;
			const dateValue = isDate ? value : moment(value, this.getActiveFormat()).toDate();
			if (!dateValue?.getTime()) {
				this.innerValue?.patchValue(null);
			} else {
				this.innerValue.patchValue(dateValue);
			}
			const dateString = isDate ? moment(value).format(this.getActiveFormat()) : value;
			this.innerInputValue = value;
			this.propagateOnChange?.(dateString);
		}

		this.cdr.detectChanges();
	}

	writeValueWithMoment(value: Date | string): void {
		if (this.innerValue?.value !== value) {
			// undefined reverts to current date with moment, null is an invalid aka empty date
			// If value is a string, assume it is already in correct date format and attempt to parse as such
			const date =
				typeof value === 'string'
					? moment(value || null, this.getActiveFormat())
					: moment(value || null);
			if (
				!date?.isValid() ||
				(this.maxDate && date.isAfter(this.maxDate)) ||
				(this.minDate && date.isBefore(this.minDate))
			) {
				this.innerValue?.patchValue(null);
			} else {
				this.innerValue.patchValue(date.toDate());
			}
			if (!this.innerValue.value) {
				this.clearInputElement();
				this.innerInputValue = this.innerValue.value;
				this.propagateOnChange?.(this.innerValue.value);
			} else {
				this.innerInputValue = date.format(this.getActiveFormat());
				this.propagateOnChange?.(date.format(this.getActiveFormat()));
				this.matInput.nativeElement.value = date.format(this.getActiveFormat());
			}
		}
	}

	onBlur() {
		this.propagateOnBlur?.();
	}

	public getValue(): GetBaseInputValue<string> {
		return {
			name: this.name,
			value: moment(this.innerValue?.value).format(this.getActiveFormat()),
			search: this.search,
		};
	}

	public setDisabledState(isDisabled: boolean): void {
		this.inputDisabled = isDisabled;
	}

	public getActiveFormat() {
		// Input does not override an undefined value with your default
		return this.format() ?? dateFormat;
	}

	public clearInputElement(): void {
		if (this.matInput) {
			this.matInput.nativeElement.value = '';
		}
	}
}
