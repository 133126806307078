import { DarkModeService } from '../../../_services/dark-mode.service';
import { ChangeDetectionStrategy, Component, input } from "@angular/core";


@Component({
    selector: 'dark-mode-toggle',
    styleUrls: ['dark-mode-toggle.styles.scss'],
    templateUrl: 'dark-mode-toggle.template.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DarkModeToggleComponent {
  variant = input('default');
  darkModeAllowed = this.darkModeService.darkModeAllowed;
  darkModeEnabled = this.darkModeService.darkModeEnabled;
  constructor(private darkModeService: DarkModeService) {};

  handleToggle(): void {
    this.darkModeService.toggleState();
  }
}
