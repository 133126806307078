import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	ElementRef,
	inject,
	OnInit,
	signal,
	viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@app/_modules/translate/translate.service';
import { BreakpointService, SettingsService } from '@app/_services';
import { combineLatestWith, filter, fromEvent } from 'rxjs';
import { IconComponent } from '../icon/icon.component';
import { FeatureFlagService } from '@app/modules/feature-flag/feature-flag.service';
import { FeatureFlags } from '@app/modules/feature-flag/feature-flags.model';

const translationKey = 'global_alert.message';

@Component({
    selector: 'global-alert',
    templateUrl: './global-alert.template.html',
    styleUrl: './global-alert.styles.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [IconComponent]
})
export class GlobalAlertComponent implements OnInit, AfterViewInit {
	translateService = inject(TranslateService);
	destroyRef = inject(DestroyRef);
	breakpointService = inject(BreakpointService);
	featureFlagService = inject(FeatureFlagService);

	alert = signal<string | undefined>(undefined);
	alertContainer = viewChild<ElementRef<HTMLDivElement>>('container');

	prevHeight = 0;

	ngOnInit(): void {
		this.setAlert();
		this.translateService.translationsUpdated$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => this.setAlert());
		if (globalThis?.window) {
			// Update container height variable on window resizes if we're in mobile.
			this.breakpointService.isMobile$
				.pipe(
					filter((isMobile) => isMobile.matches),
					combineLatestWith(fromEvent(globalThis?.window, 'resize')),
					takeUntilDestroyed(this.destroyRef),
				)
				.subscribe(([isMobile]) => {
					if (isMobile.matches) {
						this.setContainerHeightVariable();
					}
				});
		}
	}

	ngAfterViewInit(): void {
		if (this.breakpointService.isMobile()) {
			this.setContainerHeightVariable();
		}
	}

	setAlert() {
		const shouldShowTranslation = this.featureFlagService.isFlagEnabled(
			FeatureFlags.GLOBAL_ALERT_ENABLED,
		);
		if (!shouldShowTranslation) {
			this.alert.set(undefined);
		} else {
			// If the translation is the same as the key it means there's no translation
			const translation = this.translateService.get(translationKey);
			this.alert.set(translation === translationKey ? undefined : translation);
		}
	}

	setContainerHeightVariable() {
		if (this.alertContainer()) {
			const height = this.alertContainer()?.nativeElement?.offsetHeight;
			const appWrapper = document.querySelector('.app-wrapper') as HTMLElement;
			// This allows the sidemenu to adjust accordingly
			if (appWrapper && height !== this.prevHeight) {
				appWrapper?.style?.setProperty?.(
					'--alert-container-height',
					`${Math.ceil(height)}px`,
				);
				this.prevHeight = height;
			}
		}
	}
}
