import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'removeEmptyTags',
    standalone: false
})

export class RemoveEmptyTagsPipe implements PipeTransform {

  transform(value: string): string {
    return value.replace(/<\b(?!td|th)\b.+>(\s?|\&nbsp\;)<\/.+>/gmi, '');
  }

}
