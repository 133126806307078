import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'urlDecode',
    standalone: false
})

export class UrlDecodePipe implements PipeTransform {

  transform(value: any): any {

    return decodeURI(value);

  }

}
