import { Component, Input } from "@angular/core";

@Component({
    selector: 'sidebar-articles',
    templateUrl: './sidebar.articles.template.html',
    styleUrls: ['sidebar.articles.styles.scss'],
    standalone: false
})
export class SidebarArticlesComponent {
  @Input() public data;
}
