import { ChangeDetectorRef, Component, DestroyRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, input, signal } from '@angular/core';
import { SettingsService } from '@app/_services/SettingsService';
import { HttpClient } from '@angular/common/http';
import { TitleService } from '@app/_services/TitleService';
import { getLangCode, isMainPage } from '@app/_core/router-utility';
import { Router } from '@angular/router';
import { TranslateService } from '@app/_modules/translate/translate.service';
import { retry } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BreadcrumbsItem, BreadcrumbsResponse } from './breadcrumbs.interfaces';

@Component({
    selector: 'breadcrumbs',
    templateUrl: './breadcrumbs.template.html',
    styleUrls: ['./breadcrumbs.styles.scss'],
    standalone: false
})

export class BreadcrumbsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public data: BreadcrumbsItem[] = [];
  public path = input('');
  public ellipsis = input(true);
  fetching = signal(false);

  constructor(
    private settings: SettingsService,
    private http: HttpClient,
    private titleService: TitleService,
		private router: Router,
		private translate: TranslateService,
    private destroy$: DestroyRef,
    private cdr: ChangeDetectorRef,
  ) { }

  public ngOnChanges(changes: SimpleChanges) {
		if (changes.path && changes.path.currentValue !== changes.path.previousValue) {
			this.getData();
    }
  }

  public ngOnInit() {
    if (this.path() && this.path().length !== 0) {
      this.getData();
    } else if (Array.isArray(this.data) && this.data.length) {
      const title = this.translate.get(this.data?.[this.data?.length - 1]?.title);
      if (title) {
          this.titleService.setTitle(title);
        }
      }
  }

  public ngOnDestroy(): void {
    this.titleService.setTitle('');
  }

  private parseData(response: BreadcrumbsResponse): void {
		try {
      this.data = response.data.breadcrumb.map((item, i, arr) => {
        const text = item.title || item.text
        if (i === arr.length - 1) {
          this.titleService.setTitle(text);
        }
        return {
          title: text,
          link: item.link,
        };
      });
      this.cdr.markForCheck();
    } catch (err) {
      this.titleService.setTitle('');
    }
  }

  private getData(): void {
    if (this.fetching()) return;
    this.fetching.set(true);
    const params = {
      _format: 'json',
      path: this.path(),
    };
    this.data = [];
		this.http
      .get(`${this.settings.url}/api/breadcrumbs`, { params })
      .pipe(retry(2), takeUntilDestroyed(this.destroy$))
      .subscribe({
        next: (response: BreadcrumbsResponse) => {
          this.saveLanguageSwitchLinks(response);
          this.parseData(response);
          this.fetching.set(false);
          this.cdr.markForCheck();
        },
      });
	}

	navigateToMainPage() {
		const path = getLangCode() === 'et' ? '/' : `/${getLangCode()}`;
		this.router.navigate([path]);
	}

	isMainPage(link: string) {
		return isMainPage(link);
	}

	private saveLanguageSwitchLinks(response: BreadcrumbsResponse) {
		if (response?.data?.languageSwitchLinks) {
			this.settings.currentLanguageSwitchLinks =
        response.data.languageSwitchLinks.map((switchLink) => ({
          langcode: switchLink.language.id,
          path: switchLink?.url?.path,
          active: switchLink.active
        }));
		}
	}
}
