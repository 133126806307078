import { NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'skeleton',
    templateUrl: './skeleton.template.html',
    styleUrls: ['./skeleton.styles.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgSwitch, NgSwitchCase, NgSwitchDefault]
})

export class SkeletonComponent {
  @Input() type: string = 'line';
}
