import { Pipe, PipeTransform } from '@angular/core';
/**
 * Uppercases first letter of first word
 *
 * @param text any string
 * @returns modified string
 */
const ucFirst = (text: string) => {
	const [part, ...split] = text.split(/\s/g);

	const ucd = part
		.toLowerCase()
		.split(/(?=['|-])/g)
		.map((word: string) =>
			word.indexOf('-') + word.indexOf('\'') > -2
				? word.slice(0, 2).toUpperCase() + word.slice(2)
				: word.slice(0, 1).toUpperCase() + word.slice(1)
		)
		.join('');

	return [ucd, ...split].join(' ');
};

@Pipe({
    name: 'ucfirst',
    standalone: false
})
export class UcFirstPipe implements PipeTransform {
	transform(text: string): string {
		return typeof text === 'string' ? ucFirst(text) : text;
	}
}
