import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'removeProtocol',
    standalone: false
})

export class RemoveProtocolPipe implements PipeTransform {

  transform(value: any): any {
    if (value === undefined) {
      return '';
    }

    if (typeof value !== 'string') {
      return value;
    }
    return decodeURIComponent(value.replace(/.*\s*:\/\/\s*/, ''));
  }
}
