<ng-container *ngFor="let alert of alerts()">
  <div @transform [@.disabled]="disableAnimation()" [ngClass]="['alert alert--' + alert.type, alert?.modifier ? 'alert--' + alert?.modifier : '']" role="alert" aria-label="alert">
    <div class="alert__icon">
      <icon glyph="{{ alert.icon ? alert.icon : alertIcons[alert.type]}}" [size]="small ? 'medium' : 'large'"></icon>
    </div>
    <p>
      <span [innerHTML]="alert.message | translate"></span>
      <a *ngIf="alert.link" class="alert__close alert__close--link" [routerLink]="[alert.link.url]" [attr.data-test-id]="'alerts-link-' + (alert?.link?.url | slugify)">{{ alert.link.label }}</a>
    </p>
    <div class="cookie__buttons" *ngIf="alert.type === 'cookie'">
      <button htm-button (click)="cookies.agreeTerms()" data-test-id="alerts-cookie-agree-button">{{ 'agree' | translate }}</button>
      <button attr.aria-label="{{ 'button.close' | translate }}" *ngIf="alert.closeable" class="alert__close" (click)="remove(alert)" data-test-id="alerts-cookie-close-button">
        <icon glyph="x" [size]="small ? 'medium' : 'medium'"></icon>
      </button>
    </div>
    <button attr.aria-label="{{ 'button.close' | translate }}" *ngIf="alert.closeable" class="alert__close" (click)="remove(alert)" data-test-id="alerts-close-button">
      <icon glyph="x"></icon>
    </button>
  </div>
</ng-container>
