import { Pipe, PipeTransform } from '@angular/core';
import { slugifyString } from '@app/_core/utility';

@Pipe({
    name: 'slugify',
    standalone: false
})
export class SlugifyPipe implements PipeTransform {
  transform(str: string): string {
    return slugifyString(str);
  }
}
