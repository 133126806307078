import { LowerCasePipe, NgFor, NgIf, UpperCasePipe } from "@angular/common";
import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	Output
} from "@angular/core";
import { TranslateModule } from "@app/_modules/translate";
import { LanguageCodes } from "@app/_services";
import { environment } from "@env/environment";
import { LanguageSwitcherData } from "./language-switcher.model";
import { IconComponent } from "../icon/icon.component";

@Component({
    selector: "language-switcher",
    templateUrl: "language-switcher.template.html",
    styleUrls: ["language-switcher.styles.scss"],
    imports: [
        UpperCasePipe,
        LowerCasePipe,
        TranslateModule,
        IconComponent,
        NgFor,
        NgIf,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageSwitcherComponent {
	// Option to map language codes to a better visual etc
	protected mappedLangCodes = {
		et: 'est'
	};
	menuOpen = false;
	@Input({ required: true }) languageData: LanguageSwitcherData;
	@Input() isInSidemenu?: boolean;
	@Output() languageChangeEvent = new EventEmitter<LanguageCodes>();
	constructor(private elementRef: ElementRef) {};
	get isVisible(): boolean {
		// Hide language switcher if disabled via env or missing the required amount of languages
		return environment.LANGUAGE_SWITCHER === true && this.languageData?.availableLanguages?.length > 1;
	}

	@HostListener('document:click', ['$event'])
	handleDocumentClick(event: MouseEvent) {
		// Close menu if it's open and click target is not inside it
		if (this.menuOpen && !this.elementRef?.nativeElement?.contains(event?.target)) {
			this.menuOpen = false;
		}
	};

	toggleDropdown() {
		this.menuOpen = !this.menuOpen;
	}

	changeLanguage(code: LanguageCodes) {
		// Don't bother emitting event if it's not an actual change
		if (code && code !== this.languageData?.activeLang?.code) {
			this.languageChangeEvent.emit(code);
			this.menuOpen = false;
		}
	}

	swapTranslationPrefix(label: string) {
		return label?.replace('frontpage', 'language_switcher');
	}
}
