import { Injectable, inject } from '@angular/core';
import { SettingsService } from '../../_services/SettingsService';
import {
	FeatureFlagExpectedValuesTypes,
	FeatureFlags,
} from './feature-flags.model';

@Injectable({
	providedIn: 'root',
})
export class FeatureFlagService {
	private readonly settingsService = inject(SettingsService);

	public getFlagValue<FeatureFlagKey extends FeatureFlags>(
		key: FeatureFlagKey
	): FeatureFlagExpectedValuesTypes[FeatureFlagKey] {
		let value = this.settingsService.get(key)?.toUpperCase();
    const upperCasedValue = value?.toUpperCase();
		if (upperCasedValue === 'TRUE') {
			value = true;
		} else if (upperCasedValue === 'FALSE') {
			value = false;
		}
		return value;
	}

	public isFlagEnabled<FeatureFlagKey extends FeatureFlags>(
		key: FeatureFlagKey
	): boolean  {
		return this.getFlagValue(key);
	}

	public isFlagDisabled<FeatureFlagKey extends FeatureFlags>(
		key: FeatureFlagKey,
	): boolean {
		return !this.getFlagValue(key);
	}
}
