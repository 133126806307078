<div class="loader" aria-busy="true" aria-live="polite">
	<svg
		version="1.1"
		id="Layer_1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 200 190"
		style="enable-background: new 0 0 200 190"
		xml:space="preserve"
	>
		<path
			fill="#F8B243"
			d="M30,60c16.6,0,30-13.4,30-30S46.6,0,30,0S0,13.4,0,30S13.4,60,30,60z"
		/>
		<path
			fill="#D5401A"
			d="M90,190c22.1,0,40-17.9,40-40s-17.9-40-40-40c-22.1,0-40,17.9-40,40S67.9,190,90,190z"
		/>
		<path
			fill="#15ADC2"
			d="M170,90c16.6,0,30-13.4,30-30c0-16.6-13.4-30-30-30s-30,13.4-30,30C140,76.6,153.4,90,170,90z"
		/>
	</svg>
	<div class="loader--label">
		@if (message()) {
			<div>{{ message() }}</div>
		} @else if (slowNetwork()) {
			<div innerHTML="{{ 'loader.text' | translate }}"></div>
		}
	</div>
	<!--/label-->
</div>
<!--/loader-->
