import { ChangeDetectionStrategy, Component, OnInit, signal } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateModule } from '@app/_modules/translate';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'skip-to-content',
    templateUrl: './skip-to-content.template.html',
    styleUrls: ['./skip-to-content.styles.scss'],
    imports: [TranslateModule],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class SkipToContentComponent implements OnInit {
  public skipPath = signal('#content');
  constructor(public router: Router) {}

  ngOnInit(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.skipPath.set(`${this.router.url.replace('#content', '')}#content`);
    });
  }

  handleClick(event: Event) {
    event?.preventDefault?.();
    globalThis?.document?.getElementById?.('content')?.focus?.();
  }
}
