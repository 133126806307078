import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'shorten',
    standalone: false
})
export class ShortenPipe implements PipeTransform {
  transform(text: string, length: number = 0, suffix: string = '', wordBreak: boolean = true): string {
    if (typeof text !== 'string') {
      return text;
    }

    if (text.length > length) {
      if (wordBreak) {
        return text.slice(0, length) + suffix;
      }

      if (text.includes(' ', length)) {
        return text.slice(0, text.indexOf(' ', length)) + suffix;
      }
    }

    return text;
  }
}