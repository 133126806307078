import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AssetsModule } from '@app/_assets';
import { getLangCode } from '@app/_core/router-utility';
import { TranslateModule } from '@app/_modules/translate';
import { AppPipes } from '@app/_pipes';
import {
	DarkModeService,
	LanguageCodes,
	LanguageService,
	SidemenuService,
} from '@app/_services';
import { AuthGateDirective } from '@app/components/auth-gate/auth-gate.directive';
import { LanguageSwitcherComponent } from '@app/components/language-switcher/language-switcher.component';
import { Subject, combineLatestWith, map } from 'rxjs';
import { HeaderAuthComponent } from '../header-auth/header-auth.component';
import { HeaderInnerComponent } from '../header-inner/header-inner.component';
import { HeaderNotificationsComponent } from '../header-notifications/header-notifications.component';
import { HeaderSearchComponent } from '../header-search/header-search.component';

@Component({
	selector: 'htm-header',
	templateUrl: './header.template.html',
	styleUrls: ['header.styles.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	// TODO: remove assets module when possible
	imports: [
		HeaderInnerComponent,
		AsyncPipe,
		AssetsModule,
		TranslateModule,
		AppPipes,
		LanguageSwitcherComponent,
		HeaderSearchComponent,
		HeaderAuthComponent,
		AuthGateDirective,
		HeaderNotificationsComponent,
	],
})
export class HeaderComponent implements OnDestroy {
	destroy$ = new Subject<void>();
	menuOpen$ = this.sidemenuService.isVisibleSubscription;
	theme$ = this.darkModeService.darkModeEnabled.pipe(
		combineLatestWith(this.sidemenuService.themeSubscription),
		map(([darkModeEnabled, theme]) => {
			// Override theme in dark mode
			return darkModeEnabled ? 'dark' : theme;
		}),
	);
	constructor(
		private sidemenuService: SidemenuService,
		private router: Router,
		private darkModeService: DarkModeService,
		// Being used in template
		private languageService: LanguageService,
	) {}

	ngOnDestroy(): void {
		this.destroy$.next();
	}

	toggleMenu() {
		this.sidemenuService.toggle();
	}

	handleLogoClick() {
		const path =
			getLangCode() === LanguageCodes.ESTONIAN ? '/' : `/${getLangCode()}`;
		this.router.navigate([path]);
	}
}
