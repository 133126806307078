import { isPlatformBrowser } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	inject,
	input,
	OnInit,
	PLATFORM_ID,
	signal,
} from '@angular/core';
import { TranslateModule } from '@app/_modules/translate';
import { SettingsService } from '@app/_services';

@Component({
	selector: 'loader',
	templateUrl: './loader.template.html',
	styleUrls: ['./loader.styles.scss'],
	imports: [TranslateModule],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent implements OnInit {
	private readonly settings = inject(SettingsService);
	private readonly platformId = inject(PLATFORM_ID);
	public slowNetwork = signal(false);
	message = input<string>();
	noSlowNetwork = input<boolean>();

	startTimeout() {
		if (!this.noSlowNetwork() && isPlatformBrowser(this.platformId)) {
			const timeOut = this.settings?.data?.loader?.timeout ?? 3000;

			setTimeout(() => {
				this.slowNetwork.set(true);
			}, timeOut);
		}
	}

	ngOnInit() {
		this.startTimeout();
	}
}
