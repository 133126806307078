import { Component, Input } from '@angular/core';

@Component({
    selector: 'article-layout',
    templateUrl: './article.layout.template.html',
    styleUrls: ['./article.layout.styles.scss'],
    standalone: false
})

export class ArticleLayout {
  @Input() sidebar: any = { entity: {} };
  @Input() feedbackNid: string = '';
}
