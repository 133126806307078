import { Clipboard } from '@angular/cdk/clipboard';
import { Component, ElementRef, HostBinding, HostListener, input, signal } from '@angular/core';
import { TranslateService } from '@app/_modules/translate/translate.service';
import { AlertsService, BreakpointService } from '@app/_services';

/**
 * @deprecated Use share-new
 */
@Component({
    selector: 'share',
    templateUrl: 'share.template.html',
    styleUrls: ['share.styles.scss'],
    standalone: false
})
export class ShareComponent {
  public isFocused = signal(false);
  public isHovered = signal(false);

  public copyLink = decodeURI(window.location.href);
  public isTouchDevice = signal(false);

  pageTitle = input<string | undefined>();
  linkText = input<string | undefined>();
  @HostBinding('class') get hostClasses(): string {
    return this.isFocused() || this.isHovered() ? 'share--focused' : '';
  }

  @HostListener('mouseenter') onMouseEnter($event) {
    if (this.breakpointService.isDesktop()) {
      this.hostFocused(true, $event);
    }
  }

  @HostListener('mouseleave') onMouseLeave($event) {
    if (this.breakpointService.isDesktop()) {
      this.hostBlurred(true, $event);
    }
  }

  @HostListener('blur') onBlur($event) {
    this.isFocused.set(false);
    this.isHovered.set(false);
  }

  @HostListener('touchLeave') onTouchLeave($event) {
    this.isFocused.set(false);
    this.isHovered.set(false);
  }

  @HostListener('touchstart') onTouchStart($event) {
    this.hostFocused(true, $event);
  }

  @HostListener('document:keyup', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent): void {
		if ((event.key === 'Escape') && (this.isHovered() || this.isFocused())) {
			this.isFocused.set(false);
      this.isHovered.set(false);
		}
	}

  constructor(
    private alertsService: AlertsService,
    private translateService: TranslateService,
    private clipboard: Clipboard,
    private breakpointService: BreakpointService,
    private elementRef: ElementRef,
  ) {
    this.isTouchDevice.set(this.breakpointService.isMobile());
  }

  public share ($event: Event, type: string) {
    $event.preventDefault();
    $event.stopPropagation();
    const url = location.href;
    let shareLink = '';
    this.isFocused.set(false);
    this.isHovered.set(false);
    switch (type) {
      case 'facebook':
        shareLink = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
        break;
      case 'twitter':
        shareLink = `https://twitter.com/intent/tweet?text=${this.pageTitle()} - ${url}`;
        break;
      default:
        shareLink = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
    }

    const shareWindow = 'toolbar=0,location=0,status=0,menubar=0,scrollbars=1,resizable=1,width=560,height=460';

    return window.open(shareLink, 'targetWindow', shareWindow);
  }

  public hostFocused(hover: boolean = false, $event:any = false) {
    if (hover) {
      this.isHovered.set(true);
    } else {
      this.isFocused.set(true);
    }
    if ($event && !this.isHovered()) {
      $event.preventDefault();
    }
  }

  public hostBlurred(hover: boolean = false, $event:any = false) {
    // Don't close if focus was moved to an option
    if (this.elementRef?.nativeElement?.contains($event?.relatedTarget)) {
      return;
    }
    if (hover) {
      this.isHovered.set(false);
    } else {
      this.isFocused.set(false);
    }

    if ($event && !this.isHovered()) {
      $event.preventDefault();
    }
  }

  public copyLinkToClipboard($event) {

    if ($event === 'click' && this.breakpointService.isMobile()) {
      return false;
    }

    this.clipboard.copy(this.copyLink);

    setTimeout(
      () => {
        this.alertsService.success(
          this.translateService.get('url.copied_to_clipboard'),
          'global',
          'share',
          true,
          false,
        );
        this.isFocused.set(false);
        this.isHovered.set(false);
      },
      0);
  }

}
