import { NgTemplateOutlet } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostBinding,
	Input,
	Output,
	TemplateRef,
	inject,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@app/_modules/translate';
import { AppPipes } from '@app/_pipes';
import { LanguageCodes, SettingsService } from '@app/_services';

@Component({
	selector: 'htm-header-inner',
	templateUrl: './header-inner.template.html',
	styleUrls: ['./header-inner.styles.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [TranslateModule, NgTemplateOutlet, AppPipes, RouterLink],
})
export class HeaderInnerComponent {
	settingsService = inject(SettingsService);
	@Input({ required: true }) menuOpen: boolean;
	@Input() theme?: string;
	@Input() contents?: TemplateRef<unknown>;
	@Output() menuToggleEvent = new EventEmitter<void>();

	@HostBinding('class') get themeClass() {
		return this.theme ? `header-theme--${this.theme}` : '';
	}

	handleMenuToggle() {
		this.menuToggleEvent.emit();
	}

	get indexLink() {
		return this.settingsService.currentAppLanguage === LanguageCodes.ESTONIAN
			? '/'
			: `/${this.settingsService.currentAppLanguage}`;
	}
}
