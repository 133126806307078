import {
	AsyncPipe,
	CommonModule
} from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	ElementRef,
	HostListener,
	OnInit,
	ViewChild,
} from '@angular/core';
import {
	FormBuilder,
	ReactiveFormsModule,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { AssetsModule } from '@app/_assets';
import { TranslateModule } from '@app/_modules/translate';
import { TranslateService } from '@app/_modules/translate/translate.service';
import {
	AlertsService,
	AuthService,
	ModalService,
	SettingsService,
} from '@app/_services';
import { ButtonComponent } from '@app/components/button/button.component';
import { IconComponent } from '@app/components/icon/icon.component';
import { LabeledSeparatorComponent } from '@app/components/labeled-separator/labeled.separator.component';
import { environment } from '@env/environment';
import { AuthMethodsResponse } from './header-auth.model';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AlertsComponent } from '@app/_assets/alerts';
import { LoaderComponent } from '@app/components/loader';
import { debounceTime } from 'rxjs';
@Component({
	selector: 'htm-header-auth',
	templateUrl: './header-auth.template.html',
	styleUrls: ['./header-auth.styles.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		TranslateModule,
		CommonModule,
		AssetsModule,
		AsyncPipe,
		ReactiveFormsModule,
		ButtonComponent,
		LabeledSeparatorComponent,
		IconComponent,
		LoaderComponent,
		AlertsComponent,
	],
})
export class HeaderAuthComponent implements OnInit {
	loading = false;
	loginStatus = this.auth.isAuthenticated;
	authMenuOpen = false;
	authMethods = [];
	loginForm: UntypedFormGroup = this.formBuilder.group({
		password: ['', Validators.required],
		username: ['', Validators.required],
	});
	mobileIdForm: UntypedFormGroup = this.formBuilder.group({
		phoneNumber: ['', Validators.required],
	});
	mobileIdChallengeId = '';
	@ViewChild('authMenu') authMenu: ElementRef<HTMLDivElement>;
	// Button directive likely causes the data to change
	@ViewChild('authMenuToggle') authMenuToggle: {
		buttonRef: { nativeElement: HTMLButtonElement };
	};
	@ViewChild('loginButton') loginButton: {
		buttonRef: { nativeElement: HTMLButtonElement };
	};
	constructor(
		private settingsService: SettingsService,
		private modalService: ModalService,
		private auth: AuthService,
		private formBuilder: FormBuilder,
		private alertsService: AlertsService,
		private http: HttpClient,
		private translate: TranslateService,
		private cdr: ChangeDetectorRef,
		private destroyRef: DestroyRef,
	) {}

	ngOnInit(): void {
		this.auth.openLoginModal$
			.pipe(debounceTime(50), takeUntilDestroyed(this.destroyRef))
			.subscribe(() => this.openLoginModal());
	}

	toggleAuthMenu() {
		this.authMenuOpen = !this.authMenuOpen;
	}

	@HostListener('document:click', ['$event'])
	handleDocumentClick(event: MouseEvent) {
		// Close auth menu if click target wasn't in either the menu or the toggle
		if (this.authMenuOpen) {
			if (
				!this.authMenu?.nativeElement?.contains(event?.target as Node) &&
				!this.authMenuToggle?.buttonRef?.nativeElement?.contains(
					event?.target as Node,
				) &&
				this.loginButton?.buttonRef?.nativeElement !== (event?.target as Node)
			) {
				this.authMenuOpen = false;
			}
		}
	}

	handleLogout() {
		this.authMenuOpen = false;
		this.auth.logout();
	}

	openLoginModal(event?: Event) {
		this.loginForm.reset();
		this.mobileIdForm.reset();
		this.getAuthMethods();
		this.modalService.open('login');
	}

	getAuthMethods() {
		this.loading = true;
		let foundMethods = {};
		if (environment.DEV_AUTH) {
			foundMethods = { basic: 1, mobile_id: 1 };
		}
		this.http
			.get(`${this.settingsService.url}/auth_methods?_format=json`)
			.subscribe({
				next: (response: AuthMethodsResponse) => {
					foundMethods = { ...(response?.auth_methods || {}), ...foundMethods };
					// Add the keys from response to our methods
					// Filtering because backend could return 0 as the value of a method if it wants to remove it
					this.authMethods = Object.entries(foundMethods || {})
						.filter((method) => method?.[1])
						?.map((method) => method?.[0]);
					if (!this.authMethods?.length) {
						this.alertsService.info('login.unavailable', 'login', false);
					}
				},
				error: (response) => {
					this.alertsService.error(response?.error?.message, 'login', false);
				},
				complete: () => {
					this.loading = false;
					// Marking the component because it does not detect these changes itself
					this.cdr.markForCheck();
				},
			});
	}

	basicLogin(): void {
		this.alertsService.clear('login-modal');
		const data = { ...this.loginForm.value, auth_method: 'basic' };
		this.loading = true;
		this.auth.login(data).subscribe(() => {
			this.loading = false;
			this.modalService.close('login');
		});
	}

	taraLogin(): void {
		if (globalThis?.window) {
			globalThis.window.location.href = `${this.settingsService.url}/external-login/tara`;
		}
	}

	harIdLogin(): void {
		if (globalThis?.window) {
			globalThis.window.location.href = `${this.settingsService.url}/external-login/harid`;
		}
	}

	// This was previously commented with "needs to be finished", possible TODO?
	mobileIdLogin(): void {
		this.alertsService.clear('login-modal');
		this.loading = true;
		this.http
			.post(`${this.settingsService.url}${this.settingsService.mobileLogin}`, {
				telno: this.mobileIdForm?.controls?.phoneNumber?.value,
			})
			.subscribe({
				next: (data: any) => {
					this.mobileIdChallengeId = data.ChallengeID;
					const consecutiveForm = {
						session_code: data.Sesscode,
						id_code: data.UserIDCode,
						auth_method: 'mobile_id',
					};
					this.auth.login(consecutiveForm).subscribe({
						next: () => {
							this.modalService.close('login');
							this.mobileIdChallengeId = '';
						},
						error: (err) => {
							this.alertsService.error(
								this.translate.get(err?.error?.message),
								'login-modal',
								false,
								true,
							);
							this.mobileIdChallengeId = '';
						},
						complete: () => {
							this.loading = false;
						},
					});
				},
			});
	}

	mobileIdCancel() {
		this.mobileIdChallengeId = '';
	}
}
