import { Pipe, PipeTransform } from '@angular/core';
import { takeFirstValue } from '@app/_core/utility';

@Pipe({
    name: 'firstValue',
    standalone: false
})
export class TakeFirstValue implements PipeTransform {
	transform(entry: Record<string, string>): string {
		return takeFirstValue(entry);
	}
}
