import { Component, OnInit, HostListener, Input, ElementRef, viewChild, signal } from '@angular/core';
import { BreakpointService } from '@app/_services';
import { VideoEmbedService } from '@app/_services/VideoEmbedService';

@Component({
    selector: 'dropdown-list',
    templateUrl: './dropdown-list.component.html',
    styleUrls: ['./dropdown-list.component.scss'],
    standalone: false
})
export class DropdownListComponent implements OnInit {
	@Input() data: Object[];
	@Input() reducedColumnSet = false;
	@Input() excludeTitle = false;
	@Input() links: 'vertical' | 'horizontal' = 'horizontal';
	private colsPerRow = 4;
	private resizeDebounce: any;
	private lastWidth: number = 0;
	private modal: any = false;
	public lastOpenedPosition: number = 0;
	public errMessage: any = false;

	blockModalRef = viewChild<ElementRef>('blockModal');
	openingModal = signal(false);

	constructor(
		private breakpointService: BreakpointService,
		private videoService: VideoEmbedService,
		private elementRef: ElementRef
	) {}

		ngOnInit() {
		this.calculateColsPerRow();
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		clearTimeout(this.resizeDebounce);
		this.resizeDebounce = setTimeout(() => {
			this.calculateColsPerRow();
		}, 60);
	}

	calculateColsPerRow() {
		let tmpValue: number;
		const winWidth = window.innerWidth;

		if (winWidth === this.lastWidth) {
			return false;
		}

		this.lastWidth = winWidth;

		if (winWidth > 1280 && !this.reducedColumnSet) {
			tmpValue = 4;
		} else if (winWidth > 720) {
			tmpValue = 3;
		} else {
			tmpValue = 2;
		}

		this.colsPerRow = tmpValue;
		if (this.modal) {
			const modalIndex = this.modal.index;
			this.modal.index = 9999;
			this.modalOpen(modalIndex);
		}
	}

	modalOpen(index: number) {
		this.openingModal.set(true);
		if (this.modal && this.modal.index === index) {
			this.modal = false;
			const elem = document.getElementById(`block_${index}`);
			setTimeout(() => {
				const top = elem.getBoundingClientRect().top + window.scrollY - 24;
				if (!this.breakpointService.isDesktop()) {
          window.scrollTo({ top });
        } else {
          window.scrollTo({ top, behavior: 'smooth' });
        }
      },         0);

      if (this.breakpointService.isMobile() && !this.modal) {
        elem.blur();
      }else if (this.breakpointService.isDesktop()) {
        document.getElementById(`block_button_${index}`).focus();
      }
      return false;
    }

    let position = (Math.ceil((index + 1) / this.colsPerRow) * this.colsPerRow) - 1;

    if (position > this.data.length - 1) {
      position = this.data.length - 1;
    }
    this.modal = this.data[index];
    this.modal.position = position;
    this.modal.index = index;

    if (this.modal.fieldOskaVideo) {
			const video = Array.isArray(this.modal.fieldOskaVideo)
				? this.modal.fieldOskaVideo[0]
				: this.modal.fieldOskaVideo;
      const embeddable = this.videoService.mapVideo(video);
      this.modal.videoUrl = embeddable.videoEmbed;
    }

    try {
      const tmpList = this.modal.reverseFieldOskaFieldParagraph.entities
        .filter(item => item.paragraphReference[0])
        .map(item => item.paragraphReference[0]);
      this.modal.list = this.sortByKey(tmpList, 'entityLabel');
    }catch (err) {}

    const elem = document.querySelector(`#block_${index}`);

    setTimeout(() => {
			const top = elem.getBoundingClientRect().top + window.scrollY - 24;
			if (!this.breakpointService.isDesktop()) {
				window.scrollTo({ top });
				this.lastOpenedPosition =
					window.scrollY ||
					document.documentElement.scrollTop ||
					document.body.scrollTop ||
					0;
			} else {
				window.scrollTo({ top, behavior: 'smooth' });
				document.getElementById('dropdown-list-modal-title').focus();
			}
			this.openingModal.set(false);
		}, 0);

  }

	modalClose() {
		if (this.modal && this.breakpointService.isDesktop()) {
			const elem = document.getElementById(`block_button_${this.modal.index}`);
			elem.focus();
		}
		this.modal = false;
	}

	handleModalBlur(event: FocusEvent) {
		if (this.openingModal()) return;
		if (!this.blockModalRef()?.nativeElement?.contains(event?.relatedTarget)) {
			const nextTargetIndex = Number(this.modal.index) + (this.modal.shiftState ? -1 : 1);
			if (nextTargetIndex) {
				const elem = this.elementRef.nativeElement?.querySelector(`#block_button_${nextTargetIndex}`);
				if (elem) {
					event.preventDefault();
					elem.focus();
				}
			}
		}
	}

	handleItemBlur(event: KeyboardEvent) {
		if (event.key !== 'Tab' || this.openingModal()) return;
		const targetIndex = (event?.target as HTMLButtonElement)?.getAttribute('data-item-button-index');
		const relatedTargetIndex = Number(targetIndex) + (event?.shiftKey ? -1 : 1);
		const currentModalTarget = this.elementRef.nativeElement?.querySelector(`[data-modal-index="${targetIndex}"]`);
		if (currentModalTarget) {
			event.preventDefault();
			currentModalTarget.focus();
			return;
		}
		const relatedModalTarget = this.elementRef.nativeElement?.querySelector(`[data-modal-index="${relatedTargetIndex}"]`);
		const relatedTarget = this.elementRef.nativeElement?.querySelector(`[data-item-button-index="${relatedTargetIndex}"]`);
		if (relatedModalTarget) {
			event.preventDefault();
			relatedModalTarget.focus();
		} else if (relatedTarget) {
			event.preventDefault();
			relatedTarget.focus();
		}
	}

	storeEventTabState(event: KeyboardEvent) {
		if (event.key !== 'Tab') return;
		this.modal.shiftState = event.shiftKey;
	}

	sortByKey(array: object[], key: string) {
		return array.sort((a, b) => {
			const x = a[key].toLowerCase();
			const y = b[key].toLowerCase();
			let result = 0;
			if (x < y) {
				result = -1;
			} else if (x > y) {
				result = 1;
			}
			return result;
		});
	}
}
